<template>
  <div>
    <div class="category-box winAndplayCategory favouriteGame">
      <div class="catgory_head">
        <div class="title">
          <h4>
            <img src="/static/images/star_ic.svg" class="titile_icon favicon" />
            {{ $t("favouriteGames") }}
          </h4>
        </div>
        <!-- <div class="categorytab">
          <img src="/static/images/right_arrow_ic.svg" />
        </div>-->
      </div>

      <ul>
        <li v-for="(fgames, key) in fvtGames" :key="fgames.referenceId">
          <div class="gamegrid" @click="openGameLevel(fgames.referenceId)">
            <div class="gameImgbox">
              <img
                class="lozad"
                src="../assets/images/gray_image.jpg"
                :data-src="fgames.smallIconImageUrl"
              />

              <div class="title-block">
                <div class="text-title">{{ fgames.name }}</div>
                <div class="gameStatus">
                  <span class="palyer-counter"></span>
                </div>
              </div>
              <div
                v-if="spinnerLoading && isActive === key"
                class="loader-spin-game"
              >
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import constants from "../constants";
import { lozadObserver } from "../main";
export default {
  name: "FavoriteGameCategory",
  props: {},
  data() {
    return {
      spinnerLoading: false,
      fvtGames: []
    };
  },

  methods: {
    favouriteGames() {
      try {
        this.$http
          .get(constants.BASICURL + "/games/favorite/4?wallet=GAMES_WALLET")
          .then(
            function(data) {
              this.fvtGames = data.body.games;
              this.$nextTick().then(() => {
                lozadObserver.observe();
              });
            },
            response => {
              //console.log(response);
            }
          );
      } catch (err) {
        console.log(err);
      }
    },
    openGameLevel(refId) {
      this.lastRefToListen = refId;
      this.spinnerLoading = true;
      const url = `/${refId}`;
      this.$router.push(url);
    }
  },
  mounted() {
    this.favouriteGames();
  }
};
</script>
<style></style>
